import Vue from 'vue';
import Router from 'vue-router';


Vue.use(Router);

export default new Router({
  mode: 'hash',
  base: process.env.BASE_URL,
  routes: [
    {
      path: '/',
      name: 'home',
      component: () => import(/* webpackChunkName: "home" */ './views/Home.vue')
    },
    {
      path: '/registro',
      name: 'registro',
      component: () => import(/* webpackChunkName: "registro" */ './views/Registro.vue')
    },
    {
      path: '/descargas',
      name: 'descargas',
      component: () => import(/* webpackChunkName: "registro" */ './views/Descargas.vue')
    },
    {
      path: '/login',
      name: 'login',
      component: () => import(/* webpackChunkName: "login" */ './views/Login.vue')
    },
    {
      path: '/proceso',
      name: 'proceso',
      component: () => import(/* webpackChunkName: "proceso" */ './views/Proceso.vue')
    },
    {
      path: '/activation',
      name: 'activation',
      component: () => import(/* webpackChunkName: "activacion" */ './views/Activacion.vue')
    },
    {
      path: '/dashboard',
      name: 'dashboard',
      component: () => import(/* webpackChunkName: "activacion" */ './views/Dashboard.vue')
    }
  ]
});