<template>
  <v-app>
    <v-app-bar
      app
      dark
      height="60px"
      style="background: #042E52;"
    >
      <v-toolbar
        class="mt-9"
        style="margin-top: 0px !important"
        elevation="0"
        color="#042E52"
        dense>
        <v-img
          style="margin-left:-29px;width:240px;margin-top:-39px"
          alt="Vuetify Logo"
          class="shrink mr-2"
          contain
          src="https://utaxon.com/img/utaxme.png"
          transition="scale-transition"
        />
        <v-spacer></v-spacer>
        <v-toolbar-items class="hidden-sm-and-down">
           <a href="https://www.utaxme.com"  style="background-color: #042E52;" class="v-btn v-btn--flat v-btn--router v-btn--text theme--dark v-size--default white--text" >
            Inicio
          </a>

           <a href="https://www.utaxme.com/about-us"  style="background-color: #042E52;" class="v-btn v-btn--flat v-btn--router v-btn--text theme--dark v-size--default white--text" >
            Nosotros
          </a>

           <a href="https://www.utaxme.com/our-services"  style="background-color: #042E52;" class="v-btn v-btn--flat v-btn--router v-btn--text theme--dark v-size--default white--text" >
            Servicios
          </a>

           <a href="https://www.utaxme.com/contact-us"  style="background-color: #042E52;" class="v-btn v-btn--flat v-btn--router v-btn--text theme--dark v-size--default white--text" >
            Contacto
          </a>


        <a v-if="this.$store.state.logeado" href="https://www.utaxme.com"  style="background-color: #042E52;" class="v-btn v-btn--flat v-btn--router v-btn--text theme--dark v-size--default white--text" >
            Salir
          </a>
          <v-btn v-else  style="background-color: #6292ba;" 
            color="white"
            key="power_settings_new"
            to="/login"
            text
          >Login</v-btn>
          

        </v-toolbar-items>

        <v-menu offset-y>
          <template v-slot:activator="{ on }">
            <v-app-bar-nav-icon style v-on="on" class="hidden-md-and-up"></v-app-bar-nav-icon>
          </template>
          <v-list style="background-color:#6292ba;">
            <v-list-item >
              <!-- https://cssgradient.io/ -->
              
             <v-list-item-title 
                style="color:white;text-transform: uppercase;text-indent: 0.0892857143em;font-weight: 500;letter-spacing: 0.0892857143em;">
                <span style="color:#2979FF;margin-left:10px;"></span>
                  <a href="https://www.utaxme.com"  style="background-color: #6292ba;" class="v-btn v-btn--flat v-btn--router v-btn--text theme--dark v-size--default white--text" >
                    Inicio
                  </a>
              </v-list-item-title>
            </v-list-item>

             <v-list-item >
              
             <v-list-item-title 
                style="color:white;text-transform: uppercase;text-indent: 0.0892857143em;font-weight: 500;letter-spacing: 0.0892857143em;">
                <span style="color:#2979FF;margin-left:10px;"></span>
                 <a href="https://www.utaxme.com/about-us"  style="background-color: #6292ba;" class="v-btn v-btn--flat v-btn--router v-btn--text theme--dark v-size--default white--text" >
                  Nosotros
                </a>
              </v-list-item-title>
            </v-list-item>

             <v-list-item >
              
             <v-list-item-title 
                style="color:white;text-transform: uppercase;text-indent: 0.0892857143em;font-weight: 500;letter-spacing: 0.0892857143em;">
                <span style="color:#2979FF;margin-left:10px;"></span>
                 <a href="https://www.utaxme.com/our-services"  style="background-color: #6292ba;" class="v-btn v-btn--flat v-btn--router v-btn--text theme--dark v-size--default white--text" >
                  Servicios
                </a>
              </v-list-item-title>
            </v-list-item>


             <v-list-item >
              
             <v-list-item-title 
                style="color:white;text-transform: uppercase;text-indent: 0.0892857143em;font-weight: 500;letter-spacing: 0.0892857143em;">
                <span style="color:#2979FF;margin-left:10px;"></span>
                 <a href="https://www.utaxme.com/contact-us"  style="background-color: #6292ba;" class="v-btn v-btn--flat v-btn--router v-btn--text theme--dark v-size--default white--text" >
                  Contacto
                </a>
              </v-list-item-title>
            </v-list-item>
            <v-list-item>
            <v-list-item-title 
                style="color:white;text-transform: uppercase;text-indent: 0.0892857143em;font-weight: 500;letter-spacing: 0.0892857143em;">
                <span style="color:#2979FF;margin-left:10px;"></span>
                 <a v-if="this.$store.state.logeado" href="https://www.utaxme.com"  style="background-color: #6292ba;" class="v-btn v-btn--flat v-btn--router v-btn--text theme--dark v-size--default white--text" >
                  Salir
                </a>
                <v-btn v-else  style="background-color: #6292ba;" 
                  color="white"
                  key="power_settings_new"
                  to="/login"
                  text
                >Login</v-btn>
              </v-list-item-title>
            </v-list-item>

          </v-list>
        </v-menu>
      </v-toolbar>
    </v-app-bar>
    <!-- Sizes your content based upon application components -->
    <v-main style="padding-top:10px!important;">
      <!-- Provides the application the proper gutter -->
      <v-container fluid >
        <!-- If using vue-router -->
        <router-view></router-view>
      </v-container>
    </v-main>
  </v-app>
</template>

<script>
import router from '../src/router'

export default {
  name: "App",

  components: {},

  data: () => ({
    menu: [
      
      { icon: "power_settings_new", title: "Login", link: "/login" }
    ]
  }),
  created(){
    
  },
  methods: {
    menuItems() {
      return this.menu;
    },
    push(link){
       router.push(link); 
    }
  }
};
</script>
<style scoped>
html {
  overflow-y: hidden;
}
</style>
