import Vue from 'vue';
import Vuex from 'vuex';

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    nuevoFlujo:false,
    catalogosProductos:[],
    paqueteNuevoFlujo:'',
    montoNuevoFlujo:0.0,
    datosTarjetaValidos:true,
    regimenFiscalUsuarioId:"",
    regimenFiscalUsuarioDesc:"",
    listRegFiscalUsuario:[],
    countCFDIS:0,
    catalogoPlataformas: [],
    catalogoPlataformasD: [],
    billerticket:false,
    logeado:true,
    windowHeight:0,
    dialogPropuesta:false,
    dialogPropuestaMasTarde:false,
    loading:true,
    mobile:false,
    pc:false,
    tablet:false,
    sesion:{
      email:'',
      step:null
    },
    dashboard:{
      email:'No se obtuvo información',
      razonSocial:'No se obtuvo información',
      domicilio:'No se obtuvo información',
      facturasEmitidasEgresos:0,
      ivaTrasladadoEgresos:0,
      subtotalEgresos:0,
      totalEgresos:0,
      facturasEmitidasIngresos:0,
      ivaTrasladadoIngresos:0,
      ivaRetenidoIngresos:0,
      isrRetenido:0,
      subtotalIngresos:0,
      totalIngresos:0,
      anio:'2018',
      mes:'7',
      estatus:0,
      montoPagar:0,
      montoRecargosMulta:0,
      acuseUrl:'',
      opinionUrl:'',
      declaracionUrl:'',
      constanciaUrl:'',
      fechaPeriodo:new Date().toISOString().substr(0, 7),
      lugarExpedicion:'',
      regimenFiscal:'',
      idSat:'',
      isCSD:false
    },
    cliente:{
      nombre:'',
      email:'',
      password:'',
      telefono:'',
      rfc:'',
      tieneRFC:false,
      tieneFIEL:false,
      propiedades:0,
      subtotal:0,
      iva:0,
      total:0,
      pagar:false,
      mastarde:true,
      cardNumber:'',
      cardName:'',
      cardMonth:'',
      cardYear:'',
      cardCvv:'',
      estatusTransaccion:null,
      codigoTransaccion:null,
      fechaTransaccion:null,
      tokenPublico:'',
      folio:'',
    },
   variables:{
     costoBase:205.00,
     costoFacturaelectronica:2.5,
     tasaIva: 16,
     comisionCobroPorTarjeta:0.0350,
     facturasPromedioXPropiedad:40,
     costoFacturaPromedioBase:100.00,
     costoAtencionPropiedad:90.00,
     totalPorPropiedad:190.00,
     descuentoPorcentaje:0.10
   }
  },
  getters: {
    getCliente: state => {
      return state.cliente;
    },
    getSesion: state => {
      return state.sesion;
    },
    getCalculoTotal: state => {
    
      var subTotal = 0;
      var iva = 0;
      var total =0;
      var desde = 0.0;
      var totalDesde=0.0;
      //state.cliente.total= total;

      if(state.cliente.propiedades=='PAQUETE BÁSICO'){
       // state.cliente.propiedades=1;
        subTotal=499.00;
      }
      else if(state.cliente.propiedades=='PAQUETE AVANZADO'){
        //state.cliente.propiedades=2;
        subTotal=699.00;
      }
      else if(state.cliente.propiedades=='PAQUETE PREMIUM'){
        //state.cliente.propiedades=3;
        subTotal=1949.00;
      }
      else{
        //state.cliente.propiedades=0;
        subTotal=0.00;
      } 

      iva = subTotal * state.variables.tasaIva/100 ;
      desde = subTotal+iva;

      return desde.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
     
    },
    getDesde: state => {
      var subTotal = 0;
      
      //var clasificacion= 0;
      var iva = 0;
      //var comision_cobro=0.0;
      //var comisiones = 0;
      var total =0;
      //var totalSinComisiones=0;
      //var montoServicios = 0.0;
      //var monotDescuento = 0.0;
      var desde = 0.0;
      //var ivaDesde = 0.0;
      var totalDesde=0.0;
     
      

      if(state.cliente.propiedades=='PAQUETE BÁSICO'){
       // state.cliente.propiedades=1;
        subTotal=499.00;
      }
      else if(state.cliente.propiedades=='PAQUETE AVANZADO'){
        //state.cliente.propiedades=2;
        subTotal=699.00;
      }
      else if(state.cliente.propiedades=='PAQUETE PREMIUM'){
        //state.cliente.propiedades=3;
        subTotal=1949.00;
      }
      else{
        //state.cliente.propiedades=0;
        subTotal=0.00;
      } 

      iva = subTotal * state.variables.tasaIva/100 ;
      desde = subTotal+iva;

      var subtotalFinal = subTotal.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
      if(subtotalFinal.includes('.'))
           return subtotalFinal;
           else
           return subtotalFinal + ".00";

     
    },
    getIVADesde: state => {
      

      var subTotal = 0;
      var iva = 0;
      var total =0;
      var desde = 0.0;
      var totalDesde=0.0;
      //state.cliente.total= total;

      if(state.cliente.propiedades=='PAQUETE BÁSICO'){
       // state.cliente.propiedades=1;
        subTotal=499.00;
      }
      else if(state.cliente.propiedades=='PAQUETE AVANZADO'){
        //state.cliente.propiedades=2;
        subTotal=699.00;
      }
      else if(state.cliente.propiedades=='PAQUETE PREMIUM'){
        //state.cliente.propiedades=3;
        subTotal=1949.00;
      }
      else{
        //state.cliente.propiedades=0;
        subTotal=0.00;
      } 

      iva = subTotal * state.variables.tasaIva/100 ;
      desde = subTotal+iva;

      return iva.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
      
     
    }
  },

  mutations: {

  },
  actions: {

  },
});
